import React from "react";
import { FormattedMessage } from "react-intl";

class Navigation extends React.Component {

    scrollToElement(element) {
        switch (element) {
            case "orders":
                this.props.sectionRefs.successfulOrdersRef.current.scrollIntoView();
                break;
            case "values":
                this.props.sectionRefs.companyValuesRef.current.scrollIntoView();
                break;
            case "history":
                this.props.sectionRefs.companyHistoryRef.current.scrollIntoView();
                break;
            case "contacts":
                this.props.sectionRefs.contactsRef.current.scrollIntoView();
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div className="page-nav">
                <div className="page-nav-wrapper">
                    <p className="page-buttons" onClick={() => this.scrollToElement("orders")}>
                        <img className="page-nav-image" src="./connections.png" alt="Orders"></img>
                        <FormattedMessage id="Company.navOrders"
                            defaultMessage="+10 000 Successful Orders"
                            description="Company Successful Orders" />
                    </p>
                    <p className="page-buttons" onClick={() => this.scrollToElement("values")}>
                        <img className="page-nav-image" src="./values.png" alt="Values"></img>
                        <FormattedMessage id="Company.navValues"
                            defaultMessage="Company Values"
                            description="Company Values" />
                    </p>
                      {/*<p className="page-buttons" onClick={() => this.scrollToElement("history")}>
                        <img className="page-nav-image" src="./history.png" alt="History"></img>
                        <FormattedMessage id="Company.navHistory"
                            defaultMessage="Company History"
                            description="Company Company History" />
                    </p>*/}
                    <p className="page-buttons" onClick={() => this.scrollToElement("contacts")}>
                        <img className="page-nav-image" src="./contacts.png" alt="Contacts"></img>
                        <FormattedMessage id="Company.navContacts"
                            defaultMessage="Contacts"
                            description="Contacts" />
                    </p>
                </div>
            </div>
        );
    }

}

export default Navigation;
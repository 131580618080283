import React from "react"

import LanguageSelector from "./LanguageSelector"
import CompanyMotto from "./CompanyMotto"
import Navigation from "./Navigation"
import PageContent from "./PageContent"


class App extends React.Component {
    constructor(props) {
        super(props);
        this.successfulOrdersRef = React.createRef();
        this.companyValuesRef = React.createRef();
        this.companyHistoryRef = React.createRef();
        this.contactsRef = React.createRef();
    }

    render() {
        return (
            <div className="app-wrapper">
                <div className="header-container">
                    <img className="logo" src="./logo.png" alt="Personnel and Logistics Logo"></img>
                    <LanguageSelector language={this.props.languageSelection}></LanguageSelector>
                </div>
                <CompanyMotto></CompanyMotto>
                <Navigation sectionRefs={{
                    successfulOrdersRef: this.successfulOrdersRef,
                    companyValuesRef: this.companyValuesRef,
                    companyHistoryRef: this.companyHistoryRef,
                    contactsRef: this.contactsRef
                }}></Navigation>
                <PageContent sectionRefs={{
                    successfulOrdersRef: this.successfulOrdersRef,
                    companyValuesRef: this.companyValuesRef,
                    companyHistoryRef: this.companyHistoryRef,
                    contactsRef: this.contactsRef
                }} recommendations={this.props.recommendations} companydocuments={this.props.companydocuments}></PageContent>
            </div>

        );
    }
}

export default App;
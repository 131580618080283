import React from "react";
import { FormattedMessage } from "react-intl";

class LanguageSelector extends React.Component {

    chooseLanguageEn = () => {
        this.props.language("en");
    }

    chooseLanguageDe = () => {
        this.props.language("de");
    }

    chooseLanguageBg = () => {
        this.props.language("bg");
    }

    render() {
        return (
            <div className="language-selector">
                <div id="language-choice-text">
                    <FormattedMessage id="Company.languageSelector"
                        defaultMessage="Language: "
                        description="Language" />
                </div>
                <img id="language-en" className="language-icon" onClick={this.chooseLanguageEn} src="./en.png" alt="English Language"></img>
                <img id="language-de" className="language-icon" onClick={this.chooseLanguageDe} src="./de.png" alt="German Language"></img>
                <img id="language-bg" className="language-icon" onClick={this.chooseLanguageBg} src="./bg.png" alt="Bulgarian Language"></img>
            </div>
        );
    }

}

export default LanguageSelector;